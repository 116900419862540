import { useTranslation } from "next-i18next";
import Image from "next/image";
import AnchorLink from "react-anchor-link-smooth-scroll";
import PackageOption from "./PackageOption";
const Pricing = () => {
  const { t } = useTranslation();
  return (
    <section className="py-14">
      <div className="w-full mx-auto max-w-7xl">
        <div className="lg:flex w-full px-5 mx-auto lg:px-0 text-left">
          <div className="w-full xl:w-1/3 lg:w-2/6 text-left">
            <div className="flex flex-col p-8 lg:p-0 text-left">
              <strong className="mb-1 text-xs font-semibold tracking-widest text-indigo-600 uppercase">
                {t("safe-payments")}
              </strong>
              <div className="pb-4 flex text-3xl md:text-4xl ">
                <h2 className="text-indigo-600 pr-2">{t("our")}</h2>
                <h2 className="font-extralight">{t("pricing")}</h2>
              </div>

              <p className="mx-auto mb-2 text-xl font-light text-gray-500 text-opacity-70 lg:pr-10">
                {t("pricing-bundles-description")}
              </p>
              <p className="mx-auto mb-2 text-xl font-light text-gray-500 text-opacity-70 lg:pr-10">
                {t("pricing-bundles-description-two")}
              </p>
              <div className="relative">
                <Image
                  priority={true}
                  src={"/images/stripe.svg"}
                  width={400}
                  height={550}
                  alt="stripe"
                ></Image>
              </div>
            </div>
          </div>
          <div className="w-full mx-auto md:pl-16 lg:pl-20 max-w-7xl ">
            <div>
              <div className="relative space-y-12 overflow-hidden lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-10 rounded-xl ">
                <div className="relative flex flex-col p-8 bg-primary rounded-xl">
                  <div className="flex-1">
                    <h3 className="text-xl font-semibold text-neutral-600">
                      {t("pricing-small-business")}
                    </h3>
                    <div className="flex items-baseline mt-4 text-neutral-600">
                      <span className="text-5xl font-extrabold tracking-tight">
                        $ 1500 +
                      </span>
                    </div>
                    <p className="mt-6 text-gray-500">{t("pricing-message")}</p>

                    <ul role="list" className="pt-6 mt-6 space-y-6 border-t">
                      <span className="text-lg font-semibold text-neutral-600">
                        {t("pricing-whats-included")}
                      </span>

                      {[
                        t("pricing-fully-custom-website"),
                        t("pricing-single-page"),
                        t("pricing-languages"),
                        t("pricing-mobile-responsive"),
                        t("pricing-social-media-integration"),
                        t("pricing-google-maps"),
                        t("pricing-contact-form"),
                        t("pricing-wireframes-prototypes"),
                        t("pricing-3-revisions"),
                      ].map((optionName) => {
                        return (
                          <PackageOption key={optionName}>
                            <li className="flex">
                              <div
                                className={`inline-flex items-center w-6 h-6 bg-indigo-600 rounded-xl`}
                              >
                                <svg
                                  className="flex-shrink-0 w-4 h-4 mx-auto text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                  ></path>
                                </svg>
                              </div>
                              <span className="ml-3 text-black">
                                {optionName}
                              </span>
                            </li>
                          </PackageOption>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="mt-6 rounded-lg">
                    <AnchorLink
                      href={`#contact`}
                      target="_blank"
                      className="block text-center rounded-md py-2 px-4 
               
                            bg-indigo-600 text-white border-solid border-2 border-indigo-600
                            focus:outline-none outline-none hover:bg-white hover:text-indigo-600
                             hover:border-indigo-600 transition duration-200 md:py-3 md:px-6"
                      rel="noreferrer"
                    >
                      {t("get-started")}
                    </AnchorLink>
                  </div>
                </div>
                <div className="relative flex flex-col p-8 bg-indigo-600 rounded-2xl">
                  <div className="relative flex-1">
                    <h3 className="text-xl font-semibold text-white">
                      {t("entreprise")}
                    </h3>

                    <div className="flex items-baseline mt-4 text-white">
                      <span className="text-5xl font-extrabold tracking-tight">
                        $ 5000 +
                      </span>
                    </div>
                    <p className="mt-6 text-white text-solitud">
                      {t("pricing-entreprise-message")}
                    </p>

                    <ul role="list" className="pt-6 mt-6 space-y-6 border-t">
                      <span className="text-lg font-semibold text-white">
                        {t("pricing-whats-included")}
                      </span>
                      {[
                        t("pricing-fully-custom-website"),
                        t("pricing-single-page"),
                        t("pricing-languages"),
                        t("pricing-mobile-responsive"),
                        t("pricing-social-media-integration"),
                        t("pricing-google-maps"),
                        t("pricing-google-analytics"),
                        t("pricing-contact-form"),
                        t("pricing-wireframes-prototypes"),
                        t("pricing-3-revisions"),
                      ].map((optionName, index) => {
                        return (
                          <PackageOption key={optionName + index}>
                            <li className="flex">
                              <div
                                className={`inline-flex items-center w-6 h-6 bg-white rounded-xl `}
                              >
                                <svg
                                  className="flex-shrink-0 w-4 h-4 mx-auto text-indigo-600"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                  ></path>
                                </svg>
                              </div>
                              <span className="ml-3 text-white">
                                {optionName}
                              </span>
                            </li>
                          </PackageOption>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="z-50 mt-6 rounded-lg">
                    <AnchorLink
                      href={`#contact`}
                      target="_blank"
                      className="block text-center rounded-md py-2 px-4 
               
                            bg-white text-indigo-600 border-solid border-2 
                            focus:outline-none outline-none hover:bg-white hover:text-indigo-600 hover:opacity-75
                              transition duration-200 md:py-3 md:px-6 "
                      rel="noreferrer"
                    >
                      {t("get-started")}
                    </AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
